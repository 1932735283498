<template>
  <section class="fale-conosco">
    <div class="container grid-contato">
      <div class="infos">
        <!-- <h2>Contato</h2> -->

        <h1>Como podemos impulsionar o seu negócio?</h1>

        <p>
          Descubra como as nossas soluções podem ajudar a transformar sua
          empresa. Selecione a solucão ideal para você e fale com uma pessoa
          especialista.
        </p>

        <p class="spam">😉 Não se preocupe, nós nunca te enviaremos spam, ok?</p>
      </div>
      <div class="form">
        <Formulario :interesseItem="interesse"/>
      </div>
    </div>
  </section>
</template>

<script>
import Formulario from "./Formulario.vue";
export default {
  name: "Fale-conosco",
  components: { Formulario },
  props:{
    interesse:{
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
.fale-conosco {
  padding: 70px 0;
  background: #00b453;
}
h1 {
  font-size: 40px;
  color: #fff;
  line-height: 1.2;
  font-weight: 600;
  margin: 10px 0 30px 0;
}
h2,
h1 {
  color: #e1e0e7;
  font-size: 40px;
  font-weight: 700;
}
h1 {
  color: #fff;
}
p {
  color: #e1e0e7;
  line-height: 1.5;
  font-size: 18px;
}

.grid-contato {
  display: grid;
  grid-template-columns: 460px 1fr;
  gap: 150px;
}
.form h3 {
  font-size: 36px;
}
.spam{
  margin-top: 80px;
}
@media screen and (max-width: 768px) {
  h1,
  h3 {
    font-size: 32px;
  }
  p {
    font-size: 16px;
  }
  .spam{
    margin-top: 30px;
  }

  .fale-conosco {
    padding: 70px 20px;
  }
  .grid-contato {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}
</style>