<template>
  <div id="app">
    <BaseMenu />
    <main id="main">
      <transition mode="out-in">
        <router-view />
      </transition>
    </main>
    <WhatsAppFlutuante />
    <FormularioLateral />
  </div>
</template>

<script>
import BaseMenu from "@/components/BaseMenu.vue";
import WhatsAppFlutuante from "@/components/WhatsAppFlutuante.vue";
import FormularioLateral from "@/components/FormularioLateral.vue";
export default {
  name: "App",
  components: { BaseMenu, WhatsAppFlutuante, FormularioLateral },
};
</script>


<style>
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #33303e;
}
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: #ececec;
  border-radius: 5px;
  height: 50px;
}
::selection {
  color: #00b453;
  background: #c7ffe1;
}
ul {
  list-style: none;
}
/* lista */
.lista {
  margin: 10px 0;
}
.lista li {
  color: #7a7786;
  font-size: 14px;
}
.lista li + li {
  margin-top: 5px;
}

/* produtos lista */
.lista-produto li {
  color: #7a7786;
  font-size: 24px;
}
.lista-produto li + li {
  margin-top: 15px;
}
.lista-produto {
  margin: 20px 0;
}

a {
  text-decoration: none;
}
img {
  max-width: 100%;
  display: block;
}

[data-anima="top"] {
  animation: showTop 0.5s forwards;
}
@keyframes showTop {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="bottom"] {
  animation: showBottom 0.5s forwards;
}
@keyframes showBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="left"] {
  animation: showLeft 0.5s forwards;
}
@keyframes showLeft {
  from {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="right"] {
  animation: showRight 0.5s forwards;
}
@keyframes showRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.container {
  max-width: 1150px;
  margin: 0 auto;
  padding: 0 10px;
}

#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

#main {
  flex: 1;
}
.btn {
  display: block;
  font-family: "Nunito", sans-serif;
  background: #fff;
  border: 1px solid #fff !important;
  color: #00b453;
  font-weight: 600;
  font-size: 14px;
  padding: 10px 25px;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  transition: 0.3s !important;
}
.btn.disabled,
.btn:disabled {
  background: #f7f7f7;
  color: #333;
  pointer-events: none;
}
.btn:hover {
  transform: scale(1.05) !important;
}

label {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #7a7786;
  display: block;
}
.form-lateral label {
  font-size: 16px;
  margin-bottom: 10px;
}

input,
textarea,
select {
  border-radius: 5px;
  border: 1px solid #ececec;
  padding: 15px;
  height: 55px;
  transition: all 0.3s;
  font-size: 15px;
  font-family: "Nunito", sans-serif;
  margin-bottom: 15px;
  width: 100%;
  resize: none;
  /* background: #f7f7f7; */
}

.form-lateral input,
.form-lateral textarea,
.form-lateral select {
  font-size: 16px;
}

input:hover,
input:focus,
textarea:hover,
textarea:focus,
select:hover,
select:focus {
  outline: none;
  border-color: #00b453;
}
select:disabled {
  background: #ececec;
}

input[type="checkbox"] {
  border: 1px solid #ececec;
  padding: initial;
  height: initial;
  margin: initial;
  width: initial;
}
.check-item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.check-item label {
  margin: 0;
}
.check-item + .check-item {
  margin-top: 10px;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter {
  transform: translate3d(0, -20px, 0);
}

.v-leave-to {
  transform: translate3d(0, 20px, 0);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}

@media screen and (max-width: 768px) {
  .lista-produto li {
    font-size: 18px;
  }
  .form-lateral label {
    font-size: 15px;
    margin-bottom: 8px;
  }
  .form-lateral input,
  .form-lateral textarea,
  .form-lateral select {
    font-size: 15px;
  }
}

.notify {
  padding: 20px;
  background: #f7f7f7;
  border-radius: 5px;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 20px;
  font-weight: 600;
}
.notify.error {
  background: rgba(255, 6, 60, 0.1);
  color: #ff063c;
}
.notify.success {
  background: rgba(0, 180, 84, 0.2);
  color: #00b453;
}
</style>
