<template>
  <div class="bg-form" id="bg-form" v-if="showForm">
    <div class="black-side" id="black-side" @click.prevent="closeForm"></div>
    <div class="form" data-anima="right">
      <div class="etapas">
        <p>Etapa 0{{ step }} de 02</p>
        <a href="#" @click.prevent="closeForm" id="fechar">Fechar</a>
      </div>

      <form
        action=""
        class="form-lateral"
        @submit.prevent="onSubmit"
        v-if="step == 1"
      >
        <h1>Abra agora sua Conta no EnergyPay</h1>
        <p class="subtitle">
          Fique livre de burocracias e taxas. Basta preencher o formulário
          abaixo. É rapidinho!
        </p>

        <p class="notify error" data-anima="top" v-if="notifyshow == 'error'">
          Ocorreu um erro ao enviar os dados.
          <br />Entre em contato com meajuda@energypay.me
        </p>

        <label for="_nome">Nome completo</label>
        <input
          type="text"
          id="_nome"
          placeholder="Nome completo"
          required
          v-model="lead.nome"
        />

        <label for="_email">Endereço de e-mail</label>
        <input
          type="email"
          id="_email"
          placeholder="seuemail@gmail.com"
          required
          v-model="lead.email"
        />

        <label for="_telefone">Número de telefone</label>
        <input
          type="tel"
          id="_telefone"
          placeholder="(##) ####-####"
          required
          v-model="lead.telefone"
        />

        <div class="check-item">
          <input type="checkbox" id="_cnpj" v-model="lead.has_cnpj" />
          <label for="_cnpj">Tenho CNPJ ativo</label>
        </div>

        <div class="check-item">
          <input type="checkbox" id="_termos" v-model="lead.termos_politicas" />
          <label for="_termos"
            >Estou de acordo com a Política de Privacidade do EnergyPay.</label
          >
        </div>

        <div class="subtitle">
          <p>
            Após preencher o formulário, você precisa baixar o App EnergyPay
            para concluir sua solicitação e continuar a abertura da sua conta.
          </p>
        </div>

        <button class="btn" :disabled="!lead.termos_politicas">
          {{ text_btn }}
        </button>
      </form>

      <div class="step" v-if="step == 2" data-anima="right">
        <h1>Quase lá! Falta pouco pra você abrir sua conta.</h1>
        <p class="subtitle">
          Aponte a câmera do celular para o QR code e faça seu cadastro.
        </p>

        <div class="qr-code">
          <img src="@/assets/img/qrcode.png" alt="" />
          <p>
            Importante: se a opção de leitura de QR não for compatível com seu
            celular, você pode clicar no botão abaixo, também enviaremos um
            e-mail para você.
          </p>
        </div>

        <button class="btn" @click.prevent="openApp">
          Cadastrar no EnergyPay
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";
import createLead from "@/services/index.js";

export default {
  name: "FormularioLateral",

  data() {
    return {
      step: 1,
      showForm: false,
      text_btn: "Continuar",
      lead: {
        nome: "",
        telefone: "",
        email: "",
        termos_politicas: false,
        has_cnpj: false,
      },
    };
  },
  methods: {
    openApp() {
      // window.open("https://play.google.com/store/search?q=energypay&c=apps");
      window.open("https://conta.energypay.me/cadastro");
    },
    onSubmit() {
      this.loading = true;
      this.notifyshow = "";

      this.text_btn = "Carregando ...";

      const data = {
        ...this.lead,
      };
      data.has_cnpj = data.has_cnpj ? 1 : 0;
      data.termos_politicas = data.termos_politicas ? 1 : 0;

      createLead(data)
        .then((r) => {
          if (r.status == 200) {
            this.step = 2;
          } else {
            this.notifyshow = "error";
          }
        })
        .catch(() => {
          this.notifyshow = "error";
        })
        .finally(() => {
          this.loading = false;
          this.text_btn = "Continuar";
        });
    },

    closeForm(e) {
      if (e.target.id == "black-side" || e.target.id == "fechar") {
        this.showForm = false;
      }
    },
  },
  created() {
    // eventBus
    EventBus.$on("formLateral", (data) => {
      this.showForm = data;
    });
  },
};
</script>

<style scoped>
.bg-form {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: 0;
  z-index: 9999 !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
}
.black-side {
  background: rgba(0, 0, 0, 0.8);
  margin-right: -10px;
}
.form {
  background: #fff;
  padding: 50px 70px;
  max-width: 50%;
  height: 100vh;
  position: fixed;
  top: 0px;
  right: 0;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  overflow: scroll;
}
.etapas {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.etapas a {
  color: #00b453;
  font-weight: 600;
}
.btn {
  color: #fff;
  background: #00b453;
  border: none !important;
  padding: 12px 30px !important;
  font-size: 16px;
  display: block;
  width: 100%;
  margin-top: 10px;
}

h1 {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
  margin: 20px 0 10px 0;
}
p {
  color: #7a7786;
  line-height: 1.5;
  font-size: 16px;
}
.subtitle {
  margin-bottom: 20px;
  font-size: 18px;
}
.subtitle p {
  margin: 20px 0;
  margin-bottom: 30px;

  font-size: 18px;
}

.qr-code img {
  max-width: 250px;
  margin: 15px 0;
}
.qr-code p {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .bg-form {
    grid-template-columns: 1fr;
  }
  .form {
    padding: 30px;
    max-width: 100%;
  }
  .black-side {
    display: none;
  }

  h1 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }

  .subtitle p {
    margin: 20px 0;
    font-size: 16px;
  }

  .btn {
    margin-bottom: 60px;
  }
}
</style>