<template>
  <section class="container faq">
    <div>
      <h1>Perguntas <br />Frequentes</h1>
      <p>
        Qualquer dúvida é só entrar em contato no email meajuda@energypay.me ou
        pelo WhatsApp
      </p>
    </div>
    <div class="perguntas">
      <div v-for="pergunta in perguntas" :key="pergunta.title">
        <FaqItem :pergunta="pergunta" />
      </div>
    </div>
  </section>
</template>

<script>
import FaqItem from "./FaqItem.vue";
export default {
  components: { FaqItem },
  name: "Faq",
  data() {
    return {
      perguntas: [
        {
          title: "Como solicitar o saque no App",
          text: "Neste sentido, a constante divulgação das informações garante a contribuição de um grupo importante na determinação das condições inegavelmente apropriadas.",
        },
        {
          title: "Taxas da plataforma",
          text: "O cuidado em identificar pontos críticos na adoção de políticas descentralizadoras cumpre um papel essencial na formulação de todos os recursos funcionais envolvidos.",
        },
        {
          title: "Primeiro acesso",
          text: "Do mesmo modo, a consulta aos diversos militantes ainda não demonstrou convincentemente que vai participar na mudança do retorno esperado a longo prazo.",
        },
        {
          title: "Como realizar transferência pelo App",
          text: "O cuidado em identificar pontos críticos no início da atividade geral de formação de atitudes ainda não demonstrou convincentemente que vai participar na mudança do levantamento das variáveis envolvidas.",
        },
        {
          title: "Validação de identidade",
          text: "O que temos que ter sempre em mente é que o novo modelo estrutural aqui preconizado não pode mais se dissociar do processo de comunicação como um todo.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.faq {
  padding: 70px 0;
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 50px;
  height: auto;
  transition: 0.3s;
}

h1 {
  font-size: 40px;
  color: #fff;
  line-height: 1.2;
  font-weight: 600;
  margin: 10px 0 30px 0;
}
h2,
h1 {
  color: #e1e0e7;
  font-size: 40px;
  font-weight: 700;
}
h1 {
  color: #33303e;
}
p {
  color: #7a7786;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .faq {
    padding: 0 20px;
    grid-template-columns: 1fr;
  }
  h2,
  h1 {
    font-size: 32px;
  }
  
}
</style>