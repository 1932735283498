<template>
  <ul data-anima="top" :class="{ ativo: show }" :style="{ left: `${left}px` }">
    <li v-for="link in links" :key="link.title" @click="closeMenu">
      <a :href="`/#${link.hash}`" >
        <span>{{ link.title }}</span>
        <span>{{ link.subtitle }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
import { EventBus } from "@/main.js";

export default {
  name: "MenuProdutosMobile",
  data() {
    return {
      links: [
        {
          title: "Financiamento Solar",
          subtitle:
            "Tenha acesso à melhor linha de financiamento para energia solar",
          hash: "financiamento-solar",
        },
        {
          title: "Meio de Pagamento",
          subtitle: "O melhor meio de pagamento para você receber online",
          hash: "meio-de-pagamento",
        },
        {
          title: "Seguro Solar",
          subtitle: "Venda mais oferecendo seguro solar para seus clientes",
          hash: "seguro-solar",
        },
        {
          title: "Material Fotovoltaico",
          subtitle:
            "Encontre de maneira fácil, o kit solar ideal para o seu projeto!",
          hash: "material-fotovoltaico",
        },
        {
          title: "Software de Dimensionamento e Proposta",
          subtitle:
            "Aposente o Excel, Dimensione e gere proposta comercial com a Plataforma Gratuita!",
          hash: "software",
        },
        {
          title: "Plataforma de Cursos ",
          subtitle:
            "Tenha acesso ao netflix fotovoltaico, mais de 20 cursos de quem entende de energia solar.",
          hash: "plataforma-de-cursos",
        },
        {
          title: "Conta Digital Pessoa Física e Jurídica ",
          subtitle:
            "Poder de banco para a sua empresa com soluções que vão além de um banco",
          hash: "conta-digital",
        },
      ],
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    closeMenu() {
      setTimeout(() => {
        EventBus.$emit("closeMenu", true);
      });
    },
  },
};
</script>

<style scoped>
ul {
  position: absolute;
  background: #fff;
  border-radius: 6px;
  left: 530px;
  top: 75px;
  width: 500px;
  padding: 45px;
  z-index: 9999;
  display: none;
}
ul.ativo {
  display: block;
}
ul:before {
  content: "";
  display: block;
  margin: 0 auto;
  position: absolute;
  top: -10px;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}
ul::after {
  content: "";
  width: 290px;
  height: 5px;
  background: #ffc800;
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  border-radius: 5px;
}
li + li {
  margin-top: 30px;
}
li a span {
  display: block;
}
li a span:first-child {
  font-weight: 700;
  font-size: 16px;
  color: #5f5c6b;
  transition: 0.3s;
}
li a span:last-child {
  font-weight: 500;
  font-size: 14px;
  color: #8c8a97;
}
li a:hover span:first-child {
  color: #00b453;
}
</style>