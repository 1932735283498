<template>
  <div class="form">
    <p class="notify error" data-anima="top" v-if="notifyshow == 'error'">
      Ocorreu um erro ao enviar os dados.
      <br />Entre em contato com meajuda@energypay.me
    </p>

    <p class="notify success" data-anima="top" v-if="notifyshow == 'success'">
      Dados enviados com sucesso!
      <br />Em breve entraremos em contato com você
    </p>

    <form action="" @submit.prevent="onSubmit" v-if="notifyshow != 'success'">
      <label for="nome">Nome</label>
      <input
        type="text"
        id="nome"
        placeholder="Nome do cliente"
        required
        v-model="lead.nome"
        :disabled="loading"
      />

      <label for="telefone">Número de telefone</label>
      <input
        type="tel"
        id="telefone"
        placeholder="(##) ####-####"
        required
        v-model="lead.telefone"
        :disabled="loading"
      />

      <label for="email">Endereço de e-mail</label>
      <input
        type="email"
        id="email"
        placeholder="seuemail@gmail.com"
        required
        v-model="lead.email"
        :disabled="loading"
      />

      <label for="empresa">Empresa</label>
      <input
        type="empresa"
        id="empresa"
        placeholder="Qual sua empresa?"
        required
        v-model="lead.empresa"
        :disabled="loading"
      />

      <div v-if="interesseItem == ''">
        <label for="assunto">Interesse na plataforma</label>
        <select
          name="assunto"
          id="assunto"
          required
          v-model="lead.interesse"
          :disabled="loading"
        >
          <option
            :value="interesse.value"
            v-for="interesse in interesses"
            :key="interesse.value"
          >
            {{ interesse.text }}
          </option>
        </select>
      </div>

      <button class="btn">{{ text_btn }}</button>
    </form>
  </div>
</template>

<script>
import createLead from "@/services/index.js";
export default {
  name: "Formulario",
  props: {
    interesseItem: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      notifyshow: "",
      text_btn: "Cadastrar no EnergyPay",
      lead: {
        nome: "",
        telefone: "",
        email: "",
        interesse: "",
        empresa: "",
      },
      interesses: [
        {
          text: "Selecione",
          value: "",
        },
        {
          text: "Financiamento Solar",
          value: "financiamento-solar",
        },
        {
          text: "Meio de Pagamento",
          value: "meio-de-pagamento",
        },
        {
          text: "Seguro Solar",
          value: "seguro-solar",
        },
        {
          text: "Material Fotovoltaico",
          value: "material-fotovoltaico",
        },
        {
          text: "Software de Dimensionamento e Proposta",
          value: "software",
        },
        {
          text: "Plataforma de Cursos ",
          value: "plataforma-de-cursos",
        },
        {
          text: "Conta Digital Pessoa Física e Jurídica ",
          value: "conta-digital",
        },
      ],
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.notifyshow = "";

      this.text_btn = "Carregando ...";

      createLead(this.lead)
        .then((r) => {
          if (r.status == 200) {
            this.notifyshow = "success";
          } else {
            this.notifyshow = "error";
          }
        })
        .catch(() => {
          this.notifyshow = "error";
        })
        .finally(() => {
          this.loading = false;
          this.text_btn = "Cadastrar no EnergyPay";
        });
    },
  },
};
</script>

<style scoped>
.form {
  background: #fff;
  padding: 50px;
  border-radius: 5px;
}
textarea {
  height: 13vh;
}
.btn {
  color: #fff;
  background: #00b453;
  border: none !important;
  padding: 12px 30px !important;
  font-size: 16px;
  display: block;
  width: 100%;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .form {
    padding: 30px;
  }
}
</style>