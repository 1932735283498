<template>
  <div>
    <button @click.prevent="openWhats">
      <img src="@/assets/icons/whatsapp.svg" alt="Icone do WhatsApp" />
    </button>
  </div>
</template>

<script>
export default {
  name: "WhatsAppFluatuante",
  methods: {
    openWhats() {
      window.open(
        "https://api.whatsapp.com/send?phone=556231407070&text=Ol%C3%A1%2C%20preciso%20de%20ajuda"
      );
    },
  },
};
</script>

<style scoped>
button {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  overflow: hidden;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #06e650;
  position: fixed;
  bottom: 40px;
  right: 40px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999 !important;
  transition: 0.3s;
}
button:hover {
  transform: scale(1.1);
}
img {
  width: 30px;
  height: 30px;
}
@media screen and (max-width: 768px) {
  button {
    bottom: 20px;
    right: 20px;
  }
}
</style>