<template>
  <div class="pergunta">
    <div class="item-faq" :class="{ ativo: active }" @click="active = !active">
      <div class="info-faq">
        <div>
          <h3>{{ pergunta.title }}</h3>
        </div>

        <img
          src="@/assets/icons/arrow-right.svg"
          alt="Seta para direita"
          class="arrow"
        />
      </div>
    </div>
    <div
      v-if="active"
      class="info-text"
      :class="{ ativo: active }"
      data-anima="right"
    >
      <p>
        {{ pergunta.text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqItem",
  props: {
    pergunta: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style scoped>
.pergunta {
  transition: 0.3s;
  border-bottom: 1px solid rgba(225, 224, 231, 0.9);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.item-faq {
  transition: 0.3s;
  padding: 10px 0;
  cursor: pointer;
}

.info-faq {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.arrow {
  transition: 0.3s;
  transform: rotate(90deg);
}

.item-faq.ativo .arrow {
  transform: rotate(-90deg);
}

.item-faq h3 {
  font-size: 22px;
  color: #4e4b59;
  font-weight: 700;
  margin-bottom: 5px;
}
.info-text {
  transition: 0.3s;
  height: 0;
  opacity: 0;
}
.info-text.ativo {
  height: unset;
  opacity: 1;
}

.info-text p {
  color: #7a7786;
  line-height: 1.5;
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .item-faq h3 {
    font-size: 18px;
  }
  .info-text p {
    font-size: 16px;
  }
  .arrow {
    height: 12px;
  }
}
</style>