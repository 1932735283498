<template>
  <div class="container termos">
    <h1>Políticas de Privacidade</h1>
    <p>
      Ao acessar os sites e aplicativos Energy Pay e de nosso conglomerado, o
      usuário concorda e aceita integralmente esta Política de Privacidade e
      autoriza livre e espontaneamente a coleta, tratamento, utilização e
      armazenamento de seus dados de acesso e cadastro, inclusive, mas não
      limitado a, registros de navegação, cookies, internet protocol (IP),
      sistema operacional e navegador utilizado pelo Usuário, geolocalização,
      data e hora e dados cadastrais.
    </p>
    <p>
      As Informações coletadas são mantidas em sigilo, seguindo padrões de
      segurança e confidencialidade , e serão compartilhadas com empresas do
      conglomerado Energy Pay, ou dos conglomerados a que a Energy Pay pertence,
      com parceiros, ou por força de lei ou determinação de autoridade
      competente, podendo ser corrigidas ou atualizadas, a qualquer tempo, por
      meio dos nossos Canais de Atendimento.
    </p>
    <p>
      Os dados pessoais poderão ser excluídos, mediante requerimento, conforme
      orientações disponibilizadas em cada canal de acesso. O Energy Pay por
      meio das informações coletadas no seu cadastro, poderá entrar em contato
      por meio de chamadas telefônicas, e-mail, SMS, MMS, mala-direta,
      aplicativos e outros meios de comunicação disponíveis.
    </p>

    <p>
      Sobre o envio de comunicados eletrônicos (por exemplo, e-mails), poderá
      ocorrer o descadastramento clicando no link de solicitação de exclusão de
      e-mail no início ou ao final da mensagem.
    </p>

    <p>
      As Informações, dentre outras finalidades, poderão ser utilizadas nas
      ferramentas disponibilizadas para:
    </p>
    <br />
    <p>
      - desenvolver ou aperfeiçoar os recursos e funcionalidades;<br />
      - analisar o desempenho de nossas ferramentas eletrônicas;<br />
      - verificar hábitos de navegação do Usuário;<br />
      - avaliar estatísticas;<br />- aprimorar a segurança;<br />
      - melhorar a sua experiência de navegação;<br />
      - melhorar a comunicação entre o Cronos e seus clientes;<br />
      - identificar perfis e possibilitar a oferta de nossos serviços e produtos
      personalizados e das empresas que compõem o conglomerado Cronos, ou dos
      conglomerados a que o Cronos pertence.
    </p>

    <p>
      Além disto, Informações serão utilizadas para consultar fontes disponíveis
      de mercado, entidades de proteção ao crédito, redes sociais e de
      telefonia, bem como o Sistema de Informações de Crédito do Banco Central
      do Brasil (SCR).
    </p>
    <p>
      Os cookies podem ser desabilitados manualmente, contudo é possível que
      funcionalidades de acesso sejam restringidas, o que poderá impactar na
      experiência de uso.
    </p>
    <p>
      Lembramos que o intercâmbio das Informações pode estar vulnerável a ação
      de terceiros, por isso o recomendamos que os equipamentos e sistemas
      operacionais sejam mantidos atualizados e amparados por softwares de
      segurança, inclusive, devidamente protegidos por senha de segurança.
    </p>
    <p>
      Ressaltamos que não solicitamos a instalação de programas, de qualquer
      gênero, para informativos, serviços ou alertas.
    </p>
    <p>
      Os materiais, sistemas, softwares, marcas, nomes, desenhos utilizados pela
      Energy Pay e empresas que compõem o seu conglomerado, ou dos conglomerados
      à que a Energy Pay pertence são protegidos por direitos autorais e
      industriais, sendo proibida a sua reprodução, utilização, alteração ou
      transferência. Asseguramos que as informações, tais como textos, imagens,
      sons e programas disponibilizados estão em conformidade com os normativos
      e legislação vigente.
    </p>
    <p>
      O que não pode ser feito durante o acesso às nossas ferramentas
      eletrônicas:
    </p>
    <br />
    <p>
      - violar leis ou praticar quaisquer atos ilícitos, inclusive, relacionados
      às Leis nºs 9.613/98 (“Lavagem de Dinheiro”) e 12.846/13
      (“Anticorrupção”); <br />
      - praticar atos contrários à moral e aos bons costumes e/ou que violem
      direitos de terceiros; <br />
      - transmitir conteúdo de natureza erótica, pornográfica, obscena,
      difamatória ou caluniosa ou que faça apologia ao crime, uso de drogas,
      consumo de bebidas e violência ou que promova ou incite o preconceito e a
      discriminação;
      <br />
      - contaminar ou prejudicar os equipamentos do Cronos, de empresas que
      compõem o conglomerado econômico Energy Pay e/ou de terceiros, inclusive,
      mas não limitado a, transmissão de vírus, trojans, malware, worm, bot,
      backdoor, spyware, rootkit e outros; <br />
      - utilizar, para qualquer fim os materiais, sistemas, softwares, marcas,
      nomes e desenhos da Energy Pay e também das empresas que compõem o
      conglomerado econômico Energy Pay. <br />
      - A Energy Pay não se responsabiliza por prejuízos, diretos ou indiretos,
      de qualquer natureza, decorrente da utilização indevida de nossas
      ferramentas eletrônicas. <br />
      - O Cronos ou quaisquer das empresas do seu conglomerado ou dos
      conglomerados a que a Energy Pay pertença não se responsabilizam por
      qualquer dano direto ou indireto ocasionado por eventos de terceiros, como
      ataque de hackers, falhas no sistema, no servidor ou na conexão à
      internet, inclusive por ações de softwares maliciosos como vírus, cavalos
      de Tróia, e outros que possam, de algum modo, danificar o equipamento ou a
      conexão em decorrência do acesso, utilização, ou navegação, bem como da
      transferência de dados, arquivos, imagens, textos, áudios ou vídeos
      contidos neste.
    </p>

    <p>
      As regras aqui estabelecidas limitam-se às nossas ferramentas eletrônicas
      e não abrangem sites de terceiros (por exemplo, parceiros) que
      eventualmente sejam neles mencionados, ainda que por meio de links.
    </p>
    <p>
      Qualquer Informação que seja encaminhada pelo usuário, ainda que apenas
      trafegue em nossas ferramentas eletrônicas, não representam, de forma
      alguma, a nossa opinião ou a visão da Energy Pay e/ou das empresas do
      conglomerado Energy Pay.
    </p>
    <p>
      Esta Política de Privacidade está sujeita a eventuais alterações, a
      qualquer tempo, devido a mudanças nas leis ou regulamentações, utilização
      de novas tecnologias ou sempre que julgarmos necessário. Por isso é muito
      importante que sempre seja acessada.
    </p>
    <p>
      Nossa tolerância por qualquer descumprimento das regras desta Política de
      Privacidade, pelo usuário e/ou por terceiros, não constituirá renúncia ao
      direito de exigir o cumprimento de obrigações, assim como eventual
      indenização, tampouco significará perdão ou alteração desta Política de
      Privacidade.
    </p>
    <p>
      Fica, desde já, eleito o foro da Comarca da Capital do Estado de São Paulo
      para dirimir disputas ou controvérsias oriundas desta Política de
      Privacidade.
    </p>
    <p>
      A sede social da Energy Pay está localizada fisicamente à Al Rio Negro,
      161, Edifício West Point, Sala 602, Alphaville - Barueru SP,
      CEP:06454-000, e o usuário poderá tirar dúvidas por meio dos seguintes
      Canais de Atendimento: contato@energypay.me e Site: https://energypay.me
    </p>
  </div>
</template>

<script>
export default {
  name: "TermosPoliticas",
};
</script>

<style scoped>
.termos {
  padding: 70px 0 100px 0;
}
h1 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;
  margin: 10px 0 40px 0;
}
p {
  color: #7a7786;
  line-height: 1.5;
  font-size: 16px;
}
p + p {
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
  .termos {
    padding: 40px 20px;
  }

  h1 {
    font-size: 32px;
  }

  p {
    font-size: 14px;
  }
}
</style>