<template>
  <div>
    <div class="container produtos">
      <QuemSomos />
      <div class="produtos-item">
        <BeneficiosDetalhes :link="false" />
      </div>
      <div class="qr-code-item">
        <p>Aponte a câmera do celular para o QR code e faça seu cadastro.</p>

        <div class="qr-code">
          <img src="@/assets/img/qrcode.png" alt="Qr code" />
        </div>
      </div>
      <button class="btn" @click.prevent="abrirContaBtn">Saiba mais</button>
    </div>
    <Footer />
  </div>
</template>

<script>
import QuemSomos from "../components/QuemSomos.vue";
import BeneficiosDetalhes from "../components/BeneficiosDetalhes.vue";
import Footer from "../components/Footer.vue";
import { abrirConta } from "@/helpers.js";

export default {
  components: { Footer, QuemSomos, BeneficiosDetalhes },
  name: "Produtos",
  data() {},
  methods: {
    abrirContaBtn() {
      abrirConta();
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.title = `Produtos | EnergyPay`;
    });
  },
};
</script>

<style scoped>
.produtos {
  padding: 70px 0 100px 0;
}
h1 {
  font-size: 40px;
  color: #fff;
  line-height: 1.2;
  font-weight: 700;
  margin: 10px 0 40px 0;
}

p,
.lista li {
  color: #7a7786;
  line-height: 1.5;
  font-size: 24px;
}

.produtos-item {
  margin-top: -70px;
}
.qr-code-item {
  margin: 60px 0;
}
.qr-code {
  background: #f7f7f7;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.qr-code img {
  max-height: 250px;
}
/* extra */

.btn {
  color: #855b35;
  background: #ffc800;
  border: none !important;
  padding: 12px 35px !important;
  font-size: 18px;
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .produtos {
    padding: 40px 0 80px 0;
    max-width: 90%;
  }

  h1 {
    font-size: 32px;
  }

  p {
    font-size: 18px;
  }
}
</style>