<template>
  <div class="beneficio">
    <div
      class="item-beneficio"
      :id="item.hash"
      :class="{ ativo: active }"
      @click="active = !active"
    >
      <img
        :src="require(`@/assets/icons/${item.icon}.svg`)"
        :alt="item.title"
        class="icon"
      />
      <div class="info-beneficio">
        <div>
          <h3>{{ item.title }}</h3>
          <p>{{ item.subtitle }}</p>
        </div>

        <img
          src="@/assets/icons/arrow-right.svg"
          alt="Seta para direita"
          class="arrow"
        />
      </div>
    </div>
    <div v-if="active" class="info-text" data-anima="bottom">
      <p v-html="item.info"></p>
      <div v-if="item.hash == 'financiamento-solar'">
        <br />
        <div class="item-icones">
          <div class="item-icone">
            <img src="@/assets/icons/parcelamento.svg" alt="Parcelamento" />
            <p>Parcelamento em até 96 meses</p>
          </div>
          <div class="item-icone">
            <img src="@/assets/icons/carteira.svg" alt="Primeira parcela" />
            <p>Até 120 dias para pagar a primeira parcela</p>
          </div>
        </div>
      </div>
      <br />
      <router-link :to="`/${item.hash}`" class="link">
        - Saiba mais
      </router-link>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";

export default {
  name: "BeneficioItem",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {},
  created() {
    // eventBus
    EventBus.$on("mostraBeneficio", (data) => {
      if (data == `#${this.item.hash}`) {
        this.active = true;
      }
    });
  },
};
</script>

<style scoped>
.beneficio {
  padding: 20px 0;
  border-radius: 5px;
  transition: 0.3s;
  border: 1px solid #fff;
}
.item-beneficio {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  gap: 40px;
  /* padding: 20px 25px; */
  transition: 0.3s;
}
.icon {
  transition: 0.3s;
}
.arrow {
  height: 12px;
  transition: 0.3s;
  transform: rotate(90deg);
  margin-top: -50px;
}
.item-beneficio.ativo .arrow {
  transform: rotate(-90deg);
}

.beneficio + .beneficio {
  margin-top: 10px;
}
.item-beneficio h3 {
  font-size: 20px;
  color: #4e4b59;
  font-weight: 700;
  margin-bottom: 5px;
}
.item-beneficio p,
.info-text p {
  color: #7a7786;
  line-height: 1.4;
  font-size: 14px;
}

.item-beneficio .info-beneficio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.info-text {
  padding-left: 100px;
  margin-top: 10px;
}
.item-beneficio.ativo p,
.info-text p {
  font-size: 15px;
  line-height: 1.5;
}

/* extra */
.item-icones {
  display: grid;
  grid-auto-columns: 1fr 1fr;
}
.item-icone {
  display: flex;
  align-items: center;
  gap: 20px;
}
.item-icone + .item-icone {
  margin-top: 15px;
}
.item-icone p {
  /* font-size: 18px; */
  font-weight: 600;
}
.item-icone img {
  height: 20px;
}
a {
  font-size: 15px;
}
.link {
  color: #00b453;
}
.lista li{
  
}

@media screen and (max-width: 768px) {
  .icon {
    /* display: none; */
    width: 40px;
  }
  .info-text {
    padding-left: 0;
  }
  .item-beneficio {
    gap: 15px;
  }
  .item-beneficio h3 {
    font-size: 16px;
  }
}


</style>