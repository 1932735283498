<template>
  <div>
    <div class="beneficios">
      <div v-for="item in items" :key="item.title">
        <BeneficioItem :item="item" />
      </div>
    </div>
    <router-link to="/produtos" class="produtos" v-if="link"
      >Conheça outros produtos <span>_</span></router-link
    >
  </div>
</template>

<script>
import BeneficioItem from "./BeneficioItem.vue";
export default {
  components: { BeneficioItem },
  name: "BeneficiosDetalhes",
  props: {
    link: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      items: [
        {
          title: "Financiamento Solar",
          subtitle:
            "Você integrador, Tenha acesso à melhor linha de financiamento para energia solar. Faça seu cadastro na plataforma de financiamento EnergyPay.",
          hash: "financiamento-solar",
          icon: "financiamento",
          info: `Com o intuito de financiar energia solar com agilidade, sem burocracia, com um processo 100% digital e com taxas mais competitivas. A plataforma de financiamentos da Energy Pay possibilitará vender mais, tendo acesso a melhor linha de financiamento para energia solar.`,
        },
        {
          title: "Meio de Pagamento",
          subtitle:
            "O melhor meio de pagamento para você receber online, parcelar em até 12 para seu cliente e ainda receber todo valor em até 7 dias.",
          hash: "meio-de-pagamento",
          icon: "pagamentos",
          info: "Com essa ferramenta você poderá gerar pagamentos online por meio de Pix, Cartões de Crédito e Boletos Bancários com total segurança. ",
        },
        {
          title: "Seguro Solar",
          subtitle:
            "Venda mais oferecendo seguro solar para seus clientes. Kit Gerador Fotovoltaico protegido contra Roubo/Furto qualificado, Danos Elétricos, Incêndio, Queda de Raio e Outros Sinistros.",
          hash: "seguro-solar",
          icon: "seguro",
          info: `
          O Seguro Solar garante o pagamento de indenização ao segurado, por prejuízo que o mesmo possa sofrer por danos causados aos painéis solares fotovoltaicos, em consequência dos riscos previstos nas coberturas contratadas.
          <br /><br />
          
          Kit Gerador Fotovoltaico protegido contra:

          <ul class="lista">
            <li>. Roubo/Furto qualificado </li>
            <li>. Danos Elétricos  </li>
            <li>. Incêndio  </li>
            <li>. Queda de Raio </li>
            <li>. Explosão </li>
            <li>. Granizo </li>
            <li>. Furacão ou Ciclone </li>
            <li>. Inundação e Alagamento </li>
          </ul>
          `,
        },
        {
          title: "Material Fotovoltaico",
          subtitle:
            "Encontre de maneira fácil, o kit solar ideal para o seu projeto!",
          hash: "material-fotovoltaico",
          icon: "material-foto",
          info: "Você terá acesso a plataforma com os melhores equipamentos e preços do mercado, tudo integrado com o software de dimensionamento e o módulo de financiamento do kit gerador e mão de obra.",
        },
        {
          title: "Software de Dimensionamento e Proposta",
          subtitle:
            "Aposente o Excel, Dimensione e gere proposta comercial com a Plataforma Gratuita!",
          hash: "software",
          icon: "software",
          info: `Aposente o Excel, Dimensione e gere proposta comercial com a Plataforma Gratuita da Energy Pay, aqui você Dimensiona a usina, 
                Cota o Material com as melhores marcas do mercado,
                Elabora a proposta personalizada com a cara da sua empresa,
                Financia para seu cliente e
                Receba do banco ou pelos outros meios de pagamento disponibilizados na plataforma.
            `,
        },
        {
          title: "Plataforma de Cursos ",
          subtitle:
            "Tenha acesso ao netflix fotovoltaico, mais de 20 cursos de quem entende de energia solar.",
          hash: "plataforma-de-cursos",
          icon: "plataforma-cursos",
          info: `Você poderá ter acesso a 100% dos cursos disponibilizados na plataforma, no ecossistema energy pay, tudo que você movimentar será convertido em cashback utilizados para fazer cursos como:
                 <br>
                 <ul class="lista">
                  <li>. Curso de Instalador de Energia Solar </li>
                  <li>. Dimensionamento de Sistema Fotovoltaico Grupo A </li>
                  <li>. Aprenda Usar Drone e fazer Modelagem em 3D </li>
                  <li>. Dimensionamento OFF-Grid e Sistema Híbridos </li>
                  <li>. Pilares para Gerir uma Empresa de Energia Solar de Sucesso </li>
                  <li>. NR-10 Segurança em Instalações e Serviços em Eletricidade </li>
                  <li>. NR-35 Segurança em Trabalhos em Altura </li>
                  <li>. Dentre outros mais de 20 cursos </li>
                </ul>
                  `,
        },
      ],
    };
  },
};
</script>

<style scoped>
.beneficios {
  border-bottom: 1px solid rgba(225, 224, 231, 0.9);
  padding-bottom: 20px;
}

.produtos {
  color: #00b453;
  font-weight: 600;
  margin-top: 40px;
  display: inline-block;
}
.produtos span {
  color: #ffc800;
  font-weight: 600;
  margin-left: 5px;
  transition: 0.3s;
}
.produtos:hover span {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .item-beneficio h3 {
    font-size: 18px;
  }
  .item-beneficio:hover .arrow {
    display: none;
  }
  .icon {
    width: 50px;
  }
  .item-beneficio {
    gap: 20px;
  }
  .item-beneficio p {
    font-size: 13px;
  }
}
</style>