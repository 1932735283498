<template>
  <div>
    <div class="bg-black" :class="classes">
      <div class="container visualizacao">
        <h1>{{ info.title }}</h1>
        <p v-html="info.text"></p>

        <div v-if="info.title == 'Financiamento Solar'">
          <br />
          <br />
          <div class="item-icones">
            <div class="item-icone">
              <img src="@/assets/icons/parcelamento.svg" alt="Parcelamento" />
              <p>Parcelamento em até 96 meses</p>
            </div>
            <div class="item-icone">
              <img src="@/assets/icons/carteira.svg" alt="Primeira parcela" />
              <p>Até 120 dias para pagar a primeira parcela</p>
            </div>
          </div>
        </div>

        <div class="cta">
          <button class="btn" @click.prevent="abrirContaBtn">
            Quero ser EnergyPay
          </button>
          <router-link to="/produtos">Visualizar todos os produtos</router-link>
        </div>
      </div>
    </div>

    <!-- Default -->
    <FaleConosco :interesse="$route.params.id" />
    <Footer />
  </div>
</template>

<script>
import FaleConosco from "../components/FaleConosco.vue";
import Footer from "../components/Footer.vue";
import { getProduto, abrirConta } from "@/helpers.js";

export default {
  components: { Footer, FaleConosco },
  name: "FinanciamentoSolar",
  data() {
    return {
      info: {
        title: "",
        text: "",
      },
      colors: ["verde", "amarelo", "cinza", "branco"],
      randomColor: "",
      shapes: ["circle", "square", "rounded"],
      randomShape: "",
      classes: [],
    };
  },
  methods: {
    init() {
      this.randomColor = this.colors[(Math.random() * this.colors.length) | 0];
      this.randomShape = this.shapes[(Math.random() * this.shapes.length) | 0];

      this.classes = [this.randomColor, this.randomShape];

      this.info = getProduto(this.$route.params.produto);

      this.$nextTick(() => {
        document.title = `${this.info.title} | EnergyPay`;
      });
    },
    abrirContaBtn() {
      abrirConta();
    },
  },

  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.bg-black {
  background: #000 !important;
}
.bg-black::after,
.bg-black::before {
  content: "";
  width: 120px;
  height: 120px;
  display: block;
  position: absolute;
}
.bg-black.verde::after,
.bg-black.verde::before {
  border: 40px solid rgba(0, 180, 84, 0.2);
}
.bg-black.branco::after,
.bg-black.branco::before {
  border: 40px solid rgba(255, 255, 255, 0.2);
}
.bg-black.amarelo::after,
.bg-black.amarelo::before {
  border: 40px solid rgba(255, 200, 0, 0.2);
}
.bg-black.cinza::after,
.bg-black.cinza::before {
  border: 40px solid rgba(122, 119, 134, 0.2);
}
.bg-black.circle::after,
.bg-black.circle::before {
  border-radius: 50%;
}
.bg-black.square::after,
.bg-black.square::before {
  border-radius: 0;
}
.bg-black.rounded::after,
.bg-black.rounded::before {
  border-radius: 20px;
}

@keyframes anima {
  from {
    transform: translate3d(0, 40px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.bg-black::before {
  left: -70px;
  top: 200px;
  animation: anima 3s infinite alternate;
  animation-delay: 5s;
}
.bg-black::after {
  right: 0;
  bottom: 0px;
  animation: anima 3s infinite alternate;
  animation-delay: 2s;
}
.visualizacao {
  padding: 70px 0 100px 0;
  max-width: 70%;
}
h1 {
  font-size: 40px;
  color: #fff;
  line-height: 1.2;
  font-weight: 700;
  margin: 10px 0 40px 0;
}

p,
.lista li {
  color: #7a7786;
  line-height: 1.5;
  font-size: 24px;
}

/* extra */

/* extra */
.item-icones {
  display: grid;
  grid-auto-columns: 1fr 1fr;
}
.item-icone {
  display: flex;
  align-items: center;
  gap: 20px;
}
.item-icone + .item-icone {
  margin-top: 15px;
}
.item-icone p {
  /* font-size: 18px; */
  font-weight: 600;
}
.cta {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 80px;
}
.btn {
  color: #855b35;
  background: #ffc800;
  border: none !important;
  padding: 12px 35px !important;
  font-size: 18px;
}
.cta a {
  color: #7a7786;
}

@media screen and (max-width: 768px) {
  .bg-black::after,
  .bg-black::before {
    width: 60px;
    height: 60px;
    border: 20px solid rgba(0, 180, 84, 0.2);
  }
  .bg-black::after {
    bottom: 100px;
  }

  .visualizacao {
    padding: 40px 0 80px 0;
    max-width: 90%;
  }

  h1 {
    font-size: 32px;
  }

  p {
    font-size: 18px;
  }
  .item-icone img {
    height: 20px;
  }
}
</style>