<template>
  <div class="footer-geral">
    <footer class="container footer">
      <div class="top-footer">
        <img
          class="img-logo"
          src="@/assets/logo.svg"
          alt="Logotipo EnergyPay"
          @click="topSite"
        />
        <div class="item-social">
          <router-link to="/politica-de-privacidade" class="link"
            >Termos e Política de Privacidade</router-link
          >
          <p>Acompanhe nas redes</p>
          <div class="redes-sociais">
            <a
              href="https://www.youtube.com/channel/UC9_tUZtwUXYPUY2wt-ZG_xA"
              target="_blank"
            >
              <img src="@/assets/icons/youtube.svg" alt="Youtube" />
            </a>
            <a
              href="https://www.linkedin.com/company/energypay/"
              target="_blank"
            >
              <img src="@/assets/icons/linkedin.svg" alt="linkedin" />
            </a>
            <!-- <a href="https://www.facebook.com/" target="_blank">
              <img src="@/assets/icons/facebook.svg" alt="facebook" />
            </a>
            <a href="https://www.instagram.com/" target="_blank">
              <img src="@/assets/icons/instagram.svg" alt="instagram" />
            </a> -->
            <!-- <a href="https://www.twitter.com/" target="_blank">
              <img src="@/assets/icons/twitter.svg" alt="twitter" />
            </a> -->
          </div>
        </div>
      </div>
      <div class="bottom-footer">
        <div class="texto-footer">
          <!-- <p>😀</p> -->
          <p>
            ENERGY PAY SOLUCOES ENERGIA SOLAR LTDA, inscrita no CNPJ sob o nº
            44.758.809/0001-52, com sede em Alphaville, Estado de São Paulo, Al
            Rio Negro, 161, Edifício West Point, Sala 602, não é uma instituição
            financeira e não realiza operações de crédito diretamente. A Energy
            Pay é uma correspondente bancária nos termos do artigo 2º, da
            Resolução CMN nº 3.954, de 24 de fevereiro de 2011.
          </p>
        </div>

        <a href="mailto:meajuda@energypay.me">
          <span>Atendimento:</span>
          <p>meajuda@energypay.me</p>
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    topSite() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.footer-geral {
  background: #007737;
}
.footer {
  padding: 70px 0;
}
.top-footer,
.bottom-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom-footer {
  display: grid;
  grid-template-columns: 1fr 280px;
  gap: 60px;
}
.texto-footer p {
  font-size: 13px;
}

.top-footer {
  padding-bottom: 60px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.bottom-footer {
  padding-top: 60px;
}
.top-footer p {
  color: #fff;
}
.item-social,
.redes-sociais {
  display: flex;
  align-items: center;
  gap: 30px;
}

.redes-sociais a img {
  transition: 0.3;
}
.redes-sociais a img:hover {
  filter: invert(100%);
}
.bottom-footer p {
  color: #fff;
}
.bottom-footer a {
  background: rgba(255, 255, 255, 0.2);
  padding: 17px 24px;
  border-radius: 5px;
  line-height: 1.5;
}
.bottom-footer a span,
.link {
  display: block;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
}
.link {
  text-decoration: underline;
  font-size: 15px;
}
.img-logo {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .footer {
    padding: 70px 20px;
  }
  .top-footer {
    display: block;
  }
  .bottom-footer {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .top-footer .item-social {
    margin-top: 30px;
    display: block;
  }
  .top-footer .item-social .redes-sociais {
    margin-top: 20px;
  }
  .bottom-footer a {
    margin-top: 30px;
    width: auto;
    display: inline-block;
  }
  p {
    font-size: 14px;
  }
  .link {
    margin-bottom: 15px;
    font-size: 14px;
  }
}
</style>