import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Ajuda from '../views/Ajuda.vue'
import Produtos from '../views/Produtos.vue'
import VisualizacaoProduto from '../views/VisualizacaoProduto.vue'
import TermosPoliticas from '../views/TermosPoliticas.vue'
import Api from '../views/Api.vue'
import NaoEncontrado from '../views/NaoEncontrado.vue'

import {
  EventBus
} from "@/main.js";


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ajuda',
    name: 'Ajuda',
    component: Ajuda
  },
  {
    path: '/produtos/',
    name: 'Produtos',
    component: Produtos
  },
  {
    path: '/:produto',
    name: 'VisualizacaoProduto',
    component: VisualizacaoProduto
  },
  {
    path: '/politica-de-privacidade',
    name: 'TermosPoliticas',
    component: TermosPoliticas
  },
  {
    path: '/api',
    name: 'Api',
    component: Api
  },
  {
    path: '*',
    name: 'NaoEncontrado',
    component: NaoEncontrado
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
})

router.beforeEach((to, from, next) => {
  if (to.hash) {
    EventBus.$emit("mostraBeneficio", to.hash);
  }

  next();

})


export default router