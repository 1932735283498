<template>
  <div>
    <div class="ajuda-geral">
      <section class="container">
        <h1>Central de Ajuda</h1>
        <div class="ajuda-items">
          <div class="item" v-for="ajuda in ajudas" :key="ajuda.title">
            <div>
              <h4>{{ ajuda.title }}</h4>
              <p>{{ ajuda.hour }}</p>
            </div>
            <div>
              <h5>{{ ajuda.phone }}</h5>
              <p>{{ ajuda.locale }}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ajuda",
  data() {
    return {
      ajudas: [
        {
          title: "Atendimento ao Cliente",
          phone: "(62) 3140-7070",
    },
      ],
    };
  },
  created() {
    document.title = "Ajuda | EnergyPay";
  },
};
</script>

<style scoped>
.ajuda-geral {
  padding: 150px 0;
  width: 100%;
  min-height: 100vh;
  background: #000 !important;
}
h1 {
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 60px;
}

.item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 80px;
}
.item + .item {
  margin-top: 60px;
}
.item h4,
.item h5 {
  font-size: 28px;
  color: #00b453;
  font-weight: 700;
  margin-bottom: 10px;
}
.item h5 {
  color: #fff;
}
.item p {
  font-size: 18px;
  color: #929292;
}
@media screen and (max-width: 768px) {
  .ajuda-geral {
    padding: 70px 0;
  }
  h1 {
    font-size: 32px;
  }
  .item {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .item + .item {
    margin-top: 80px;
  }
}
</style>