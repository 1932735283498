<template>
  <header>
    <div class="container menu">
      <div @click="openMenu = false">
        <a href="https://energypay.me" data-anima="left">
          <img
            src="@/assets/logo.svg"
            alt="Logotipo EnergyPay"
            class="logo-img"
          />
        </a>
      </div>
      <nav data-anima="right">
        <button class="btn btn-menu" @click="openMenu = !openMenu">MENU</button>
        <ul class="menu-items">
          <li>
            <a href="#" @click.prevent="changeShowProdutos" id="produtos-energy"
              >Produtos EnergyPay</a
            >
          </li>
          <li>
            <a href="/#quem-somos">Quem somos</a>
          </li>
          <!-- <li>
            <a href="#faq">FAQ</a>
          </li> -->
          <li @click="openMenu = false">
            <router-link to="/ajuda">Ajuda</router-link>
          </li>
          <li>
            <a href="https://conta.energypay.me/" target="_blank">Login</a>
          </li>
          <li>
            <button class="btn btn-conta" @click.prevent="abrirContaBtn">
              Quero ser EnergyPay
            </button>
          </li>
        </ul>
      </nav>
    </div>

    <div class="menu-mobile" data-anima="top" :class="{ ativo: openMenu }">
      <nav class="">
        <ul>
          <li>
            <a href="#" @click.prevent="changeShowProdutos" id="produtos-energy"
              >Produtos EnergyPay</a
            >
            <MenuProdutosMobile
              :show="showMenuProdutos"
              :left="leftItem"
              v-if="isMobile"
            />
          </li>
          <li>
            <a href="/#quem-somos">Quem somos</a>
          </li>
          <!-- <li>
            <a href="#faq">FAQ</a>
          </li> -->
          <li @click="openMenu = false">
            <router-link to="/ajuda">Ajuda</router-link>
          </li>

          <li>
            <a href="https://conta.energypay.me/" target="_blank">Login</a>
          </li>
          <li>
            <button class="btn btn-conta" @click.prevent="abrirContaBtn">
              Quero ser EnergyPay
            </button>
          </li>
        </ul>
      </nav>
    </div>
    <MenuProdutos :show="showMenuProdutos" :left="leftItem" v-if="!isMobile" />
  </header>
</template>

<script>
import { EventBus } from "@/main.js";

import MenuProdutos from "./MenuProdutos.vue";
import MenuProdutosMobile from "./MenuProdutosMobile.vue";
import { abrirConta } from "@/helpers.js";

export default {
  name: "BaseMenu",
  data() {
    return {
      openMenu: false,
      leftItem: 0,
      showMenuProdutos: false,
      client: {
        width: 0,
      },
    };
  },

  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
  methods: {
    abrirContaBtn() {
      abrirConta();
    },
    changeShowProdutos() {
      this.leftItem = document
        .querySelector("#produtos-energy")
        .getBoundingClientRect().left;
      this.showMenuProdutos = !this.showMenuProdutos;
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    // eventBus
    EventBus.$on("closeMenu", () => {
      this.showMenuProdutos = false;
      this.openMenu = false;
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  components: { MenuProdutos, MenuProdutosMobile },
};
</script>

<style scoped>
header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: #000;
}
.btn-menu {
  display: none !important;
}
.menu {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menu-items {
  display: flex;
  align-items: center;
  gap: 50px;
}
.menu-items li a {
  color: #fff;
  font-weight: 600;
  transition: 0.3;
  font-size: 15px;
}
li a.disabled {
  color: #565656 !important;
  pointer-events: none;
}

.btn {
  background: transparent;
  color: #fff;
}
.btn:hover {
  background: #fff;
  color: #00b453;
}
.menu-mobile {
  width: 100%;
  min-height: 35vh;
  transition: 0.3s;
  padding: 0 20px;
  display: none;
}

@media screen and (max-width: 768px) {
  .logo-img {
    width: 100px;
  }
  .btn-menu {
    display: block !important;
  }
  .menu {
    padding: 20px;
    justify-content: space-between;
    gap: 25px;
    /* display: block; */
  }
  .menu-items {
    display: none !important;
  }

  .menu-mobile.ativo {
    display: block;
  }
  .menu-mobile li a {
    font-size: 16px;
    padding: 10px;
    padding-left: 0;
    color: #fff;
  }
  .menu-mobile li + li {
    margin-top: 20px;
  }
  .btn {
    padding: 10px 15px;
  }
  .btn-conta {
    display: none;
  }
}

.menu-items li a.router-link-exact-active,
.menu-items li a:hover,
.menu-mobile li a.router-link-exact-active,
.menu-mobile li a:hover,
.router-link-exact-active,
.router-link-active {
  color: #00b453 !important;
}
</style>