<template>
  <div class="home">
    <Main />

    <Detalhes />

    <QuemSomos id="quem-somos" />

    <!-- <Depoimentos /> -->

    <Aproveite />

    <FaleConosco id="fale-conosco" />

    <Faq id="faq" />

    <Footer />
  </div>
</template>

<script>
import Main from "@/components/Main.vue";
import Detalhes from "../components/Detalhes.vue";
// import Depoimentos from "../components/Depoimentos.vue";
import Aproveite from "../components/Aproveite.vue";
import Footer from "../components/Footer.vue";
import QuemSomos from "../components/QuemSomos.vue";
import Faq from "../components/Faq.vue";
import FaleConosco from "../components/FaleConosco.vue";

export default {
  name: "Home",
  components: {
    Main,
    Detalhes,
    // Depoimentos,
    Aproveite,
    Footer,
    QuemSomos,
    Faq,
    FaleConosco,
  },

  created() {
    document.title = "Home | EnergyPay";
  },
};
</script>
