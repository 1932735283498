<template>
  <section class="container quem-somos">
    <div>
      <h2>Quem somos</h2>

      <h1>O futuro da energia solar já começou</h1>

      <p>
        Energy Pay é um completo Ecossistema para Integradores do setor Solar,
        onde oferece uma plataforma de serviços que é possível desde a geração
        da proposta através de um software de dimensionamento gratuito, o
        financiamento do kit gerador solar e a compra dos materiais através dos
        distribuidores parceiros com os melhores preços de mercado.
      </p>

      <p>
        Uma fintech que nasceu de quem entende de verdade das necessidades dos
        profissionais do setor fotovoltaico, fundada pelo engenheiro eletricista
        Jheferson Fernandes que atua a mais de 5 anos como professor e
        integrador de energia solar fotovoltaica.
      </p>

      <p>
        O propósito da Energy Pay é proporcionar a expansão do setor
        fotovoltaico, integrando serviços, produtos e conhecimento em um só
        lugar.
      </p>

      <p>A mais completa plataforma para o Integrador de Energia Solar</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "QuemSomos",
};
</script>

<style scoped>
.quem-somos {
  /* padding: 70px 0; */
  margin-bottom: 120px;
}
h1 {
  font-size: 40px;
  color: #fff;
  line-height: 1.2;
  font-weight: 600;
  margin: 10px 0 30px 0;
}
h2,
h1 {
  color: #e1e0e7;
  font-size: 40px;
  font-weight: 700;
}
h1 {
  color: #33303e;
}
p {
  color: #7a7786;
  line-height: 1.5;
  font-size: 18px;
}
p + p {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .quem-somos {
    padding: 0 20px;
  }
  p{
    font-size: 16px;
  }
  h2,
  h1 {
    font-size: 32px;
  }
}
</style>