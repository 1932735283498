export default function createLead(data) {
  return new Promise((resolve) => {

    console.log(data)
    const config = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    };

    fetch('https://api.plataformagame.com.br/j7', config)
      .then(response => {
        resolve(response)
      })
      .catch(() => {
        resolve(null)
      });
  })
}




// export default function createLead(data) {
//   return new Promise((resolve) => {
//       getToken().then((token) => {
//         post(token, data)
//       })
//     .finally(() => {
//       resolve(true)
//     })
//   })
// }

// function post(token, data) {
//   return new Promise((resolve) => {
//     const config = {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token.access_token}`
//       },
//       body: JSON.stringify(data, {
//         expires_i: 86400,
//         refresh_token: "9YORmXHgOI32k-Y22tZWm-rsf--oFPr8JDCQIQhBEUY"
//       })
//     };

//     fetch('https://api.rd.services/platform/contacts', config)
//       .then(response => {
//         resolve(response)
//       })
//       .catch(() => {
//         resolve(null)
//       });
//   })
// }


// async function getToken() {
//   return new Promise((resolve) => {
//     fetch(`https://api.rd.services/auth/dialog?client_id={0ede1af0-4485-4934-81f3-674d4624af42}&redirect_uri={http://energybank.netlify.app/api}`)
//       .then(response => {
//         console.log(response)
//         resolve(response)
//       })
//   })
// }