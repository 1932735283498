<template>
  <div>
    <div class="beneficios">
      <div class="item-beneficio">
        <img src="@/assets/icons/pix_taxa.svg" alt="PIX com taxa zero" />
        <div class="info-beneficio">
          <div>
            <h3>PIX com taxa zero</h3>
            <p>Para pessoa física</p>
          </div>
        </div>
      </div>
      <div class="item-beneficio">
        <img src="@/assets/icons/saque_24.svg" alt="Saques 24 horas por dia" />
        <div class="info-beneficio">
          <div>
            <h3>Saques 24 horas por dia</h3>
            <p>Consulte as taxas</p>
          </div>
        </div>
      </div>
      <div class="item-beneficio">
        <img
          src="@/assets/icons/pagamentos_simples.svg"
          alt="Pagamentos simplificados"
        />
        <div class="info-beneficio">
          <div>
            <h3>Pagamentos simplificados</h3>
            <p>Pagamentos de contas e folha de pagamento</p>
          </div>
        </div>
      </div>
    </div>
    <button class="btn" @click.prevent="abrirContaBtn">
      Quero ser EnergyPay
    </button>
  </div>
</template>

<script>
import { abrirConta } from "@/helpers.js";

export default {
  name: "AproveiteItems",
  methods: {
    abrirContaBtn() {
      abrirConta();
    },
  },
};
</script>

<style scoped>
.beneficios {
  padding: 10px 0 20px 0;
}
.item-beneficio {
  display: flex;
  align-items: center;
  gap: 25px;
}

.item-beneficio + .item-beneficio {
  margin-top: 35px;
}
.item-beneficio h3 {
  font-size: 20px;
  color: #5f5c6b;
  font-weight: 700;
  margin-bottom: 5px;
}
.item-beneficio p {
  color: #7a7786;
  line-height: 1.4;
  font-size: 18px;
}
.btn {
  color: #855b35;
  background: #ffc800;
  border: none !important;
  padding: 10px 30px !important;
  margin-top: 50px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .item-beneficio h3 {
    font-size: 20px;
  }
  .item-beneficio:hover .arrow {
    display: none;
  }
  .item-beneficio p {
    font-size: 13px;
  }
}
</style>