<template>
  <div>Enviando os dados ...</div>
</template>

<script>
import createLead from "@/services/index.js";
export default {
  name: "API",
  mounted() {
    createLead();
  },
};
</script>

<style>
</style>