<template>
  <section class="container aproveite">
    <div>
      <h2>Fuja das taxas dos bancos</h2>
      <h1>Domine o seu dinheiro com uma conta 100% digital</h1>
      <AproveiteItems />
    </div>
    <img
      src="@/assets/img/aproveite.png"
      alt="Aproveite EnergyPay"
      class="img-app"
    />
  </section>
</template>

<script>
import AproveiteItems from "./AproveiteItems.vue";
export default {
  name: "Aproveite",
  components: { AproveiteItems },
};
</script>

<style scoped>
.img-app {
  animation: anima 3s infinite alternate;
}
@keyframes anima {
  from {
    transform: translate3d(0, 40px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
.aproveite {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}
h1 {
  font-size: 40px;
  color: #fff;
  line-height: 1.2;
  font-weight: 600;
  margin: 10px 0 30px 0;
}
h2,
h1 {
  color: #e1e0e7;
  font-size: 40px;
  font-weight: 700;
}
h1 {
  color: #33303e;
}

@media screen and (max-width: 768px) {
  .aproveite {
    grid-template-columns: 1fr;
    margin-bottom: 40px;
  }
  h2,
  h1 {
    font-size: 32px;
  }
  .img-app {
    display: none;
  }
}
</style>