import {
  EventBus
} from "@/main.js";


export function abrirConta() {
  EventBus.$emit("formLateral", true);
}

// infosProdutos
export function getProduto(produto) {
  switch (produto) {
    case 'financiamento-solar': {
      return {
        title: 'Financiamento Solar',
        text: `Você integrador, Tenha acesso à melhor linha de financiamento para energia solar. Faça seu cadastro na plataforma de financiamento EnergyPay.
      <br /> <br /> 
      Com o intuito de financiar energia solar com agilidade, sem burocracia, com um processo 100 % digital e com taxas mais competitivas.A plataforma de financiamentos da Energy Pay possibilitará vender mais, tendo acesso a melhor linha de financiamento para energia solar.
      `
      }
    }
    case 'meio-de-pagamento': {
      return {
        title: 'Meio de Pagamento',
        text: `O melhor meio de pagamento para você receber online, parcelar em até 12 para seu cliente e ainda receber todo valor em até 7 dias.
        <br /> <br />
        Com essa ferramenta você poderá gerar pagamentos online por meio de Pix, Cartões de Crédito e Boletos Bancários com total segurança. 
        `
      }
    }
    case 'seguro-solar': {
      return {
        title: 'Seguro Solar',
        text: `Venda mais oferecendo seguro solar para seus clientes. Kit Gerador Fotovoltaico protegido contra Roubo/Furto qualificado, Danos Elétricos, Incêndio, Queda de Raio e Outros Sinistros.
        <br /> <br />
        O Seguro Solar garante o pagamento de indenização ao segurado, por prejuízo que o mesmo possa sofrer por danos causados aos painéis solares fotovoltaicos, em consequência dos riscos previstos nas coberturas contratadas.
        <br /><br />
        
        Kit Gerador Fotovoltaico protegido contra:

        <ul class="lista-produto">
          <li>. Roubo/Furto qualificado </li>
          <li>. Danos Elétricos  </li>
          <li>. Incêndio  </li>
          <li>. Queda de Raio </li>
          <li>. Explosão </li>
          <li>. Granizo </li>
          <li>. Furacão ou Ciclone </li>
          <li>. Inundação e Alagamento </li>
        </ul>

        `
      }
    }
    case 'material-fotovoltaico': {
      return {
        title: 'Material Fotovoltaico',
        text: `Encontre de maneira fácil, o kit solar ideal para o seu projeto!
        <br /><br />
        Você terá acesso a plataforma com os melhores equipamentos e preços do mercado, tudo integrado com o software de dimensionamento e o módulo de financiamento do kit gerador e mão de obra.
        `
      }
    }

    case 'software': {
      return {
        title: 'Software de Dimensionamento e Proposta',
        text: `Aposente o Excel, Dimensione e gere proposta comercial com a Plataforma Gratuita da Energy Pay, aqui você Dimensiona a usina, 
        Cota o Material com as melhores marcas do mercado,
        Elabora a proposta personalizada com a cara da sua empresa,
        Financia para seu cliente e
        Receba do banco ou pelos outros meios de pagamento disponibilizados na plataforma.`
      }
    }

    case 'plataforma-de-cursos': {
      return {
        title: 'Plataforma de Cursos',
        text: `Tenha acesso ao netflix fotovoltaico, mais de 20 cursos de quem entende de energia solar.
        <br /><br />
        Você poderá ter acesso a 100% dos cursos disponibilizados na plataforma, no ecossistema energy pay, tudo que você movimentar será convertido em cashback utilizados para fazer cursos como:
        <br>
        <ul class="lista-produto">
          <li>. Curso de Instalador de Energia Solar </li>
          <li>. Dimensionamento de Sistema Fotovoltaico Grupo A </li>
          <li>. Aprenda Usar Drone e fazer Modelagem em 3D </li>
          <li>. Dimensionamento OFF-Grid e Sistema Híbridos </li>
          <li>. Pilares para Gerir uma Empresa de Energia Solar de Sucesso </li>
          <li>. NR-10 Segurança em Instalações e Serviços em Eletricidade </li>
          <li>. NR-35 Segurança em Trabalhos em Altura </li>
          <li>. Dentre outros mais de 20 cursos </li>
        </ul>
        `
      }
    }

  }
}